  
#root{
  /* background-color: #eeeeee; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiInput-input{
  margin:2px;
  margin-bottom: 2px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  margin: 2px;
  position: absolute;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb7b710;
  border-radius: 5px;
  padding: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #55555527;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.appbg{
  background-size: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
p,h1,h2,h3,span,div{
  /* transition: 0.3s; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.krnav{
  /* background-color: #282c34; */
  color: #fff;
  /* margin: 10px; */
  border-radius: 10px;
  transition: .5s;
  /* border: 2px solid #fff; */
}
.krnav-active{
  background-color: #0050c7;
  color: #fff;
}
.krnav:hover{
  background-color: #002849;
  /* color: #9dc4e4; */
  /* margin: 10px; */
}
.krnav-active:hover{
  background-color: #0050c7;
  /* color: #9dc4e4; */
  /* margin: 10px; */
}
.div{
  transition: 1s;
}
.menu{
  /*box-shadow: 0px 5px 4px -3px #b5b5b5;*/
  position: relative;
  left: 0px;
  color: #1065A1;
  background-color: rgba(255, 253, 253, 0.43);
  transition: 1s;
  top: 0px;
}
.menu:hover{
  top: -3px;
  background-color: #1065A1;
  color: #fff;
  /*box-shadow: 0px 20px 4px -3px #b5b5b5;*/
}
.menu-hidden{
  left: 600%;
}
.icon-dt{
  display: flex;
  border: 2px solid #1065a1;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  margin: 3px;
  color: #1065a1;
  transition: 700ms;
}
.icon-dt:hover{
  color: #fff;
  background-color: #1065a1;
}
#menucont{
  transition: 1s;
  display: none;
  opacity: 0;
}
@media print{
  .print{
    /* width:21cm; */
    /* height:26cm; */
    overflow: hidden;
    position: absolute;
    top:0;
    left: 0;
  }
  .noprint{
    /* font-size: 10px;
     */
    display:none
    }
}
#content,#drawer{
  transition: 1s;
}
#content{
  flex-basis: 95%;
}

@media screen {
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    margin: 2px;
    position: absolute;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff00;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bbb7b710;
    border-radius: 5px;
    padding: 3px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #55555527;
  }
  
}